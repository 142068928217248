import React from 'react';
import { Hr } from '@/components/separator/HorizontalRule/HorizontalRule.styles';
import { DefaultTheme } from 'styled-components';

export interface HorizontalRuleProps {
  className?: string;
  spacing?: keyof DefaultTheme['spacing'];
}

const HorizontalRule = ({ ...props }: HorizontalRuleProps) => {
  return <Hr {...props} />;
};

export default HorizontalRule;
