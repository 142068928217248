import styled, { css } from 'styled-components';

export const Element = styled.main`
  ${({ theme, ...props }) => {
    return css`
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    `;
  }};
`;
