import React from 'react';
import { GlobalStyleInternal, Main } from '@/layout/LayoutInternal/LayoutInternal.styles';
import Header from '@/components/header';
import { Container } from '@/components/container';
import { Grid } from '@/components/grid';
import { FooterSticky } from '@/components/footer';
import WhitePaper from '@/components/elevation/WhitePaper/WhitePaper';
import Title from '@/components/typography/Title/Title';
import Text from '@/components/typography/Text/Text';
import { Element } from '@/features/errors/CustomError.styles';
import Button from '@/components/button/Button/Button';
import HorizontalRule from '../../components/separator/HorizontalRule/HorizontalRule';

const ERROR_MESSAGES = {
  0: { status: 0, title: 'Erro desconhecido', text: 'A página que tentou acessar não existe', message: 'Unknown' },
  400: {
    status: 400,
    title: 'Ocorreu um erro.',
    text: 'Acesso inválido',
    message: 'Bad Request',
  },
  401: {
    status: 401,
    title: 'Ocorreu um erro',
    text: 'Acesso inválido',
    message: 'Authentication Failed',
  },
  403: {
    status: 403,
    title: 'Ocorreu um erro',
    text: 'Acesso inválido',
    message: 'Authorization Failed',
  },
  404: {
    status: 404,
    title: 'Página não encontrada',
    text: 'A página que tentou acessar não existe',
    message: 'Página não encontrada',
  },
  405: {
    status: 405,
    title: 'Ocorreu um erro',
    text: 'Método não permitido',
    message: 'Metodo não permitido',
  },
  500: {
    status: 500,
    title: 'Ocorreu um erro',
    text: 'Server Error',
    message: 'Server Error',
  },
  502: {
    status: 502,
    title: 'Ocorreu um erro',
    text: 'Server Error',
    message: 'Bad Gatway',
  },
};

export interface CustomErrorProps {
  error: {
    status: keyof typeof ERROR_MESSAGES /*| 400 | 401 | 403 | 404 | 405 | 500 | 502 | unknown;*/ & number;
  };
}

function getErrorMessage(status: keyof typeof ERROR_MESSAGES) {
  return ERROR_MESSAGES[status] ?? ERROR_MESSAGES[0];
}

const CustomError = ({ error, ...props }: CustomErrorProps) => {
  const { status, ...rest } = error;
  const data = getErrorMessage(status);
  return (
    <>
      <GlobalStyleInternal />
      <Header navbar="sticky" />
      <Main sticky>
        <Container position="relative">
          <Grid container>
            <Grid item col={12}>
              <WhitePaper>
                <Element>
                  {data.status > 0 && (
                    <Title as="h1" size="4xl">
                      {data.status}
                    </Title>
                  )}
                  <Title as="h1" size="xl" color="secondary_500">
                    {data.title}
                  </Title>
                  <Text size="md">{data.text}</Text>
                  <HorizontalRule />
                  <Button href="/">Voltar para Home</Button>
                </Element>
              </WhitePaper>
            </Grid>
          </Grid>
        </Container>
      </Main>
      <FooterSticky />
    </>
  );
};

export default CustomError;
